<template>
  <v-card>
    <v-card-title class="align-start">
      <span>平台</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        ref="charts"
        :options="chartOptions"
        :series="chartData"
        height="318"
      ></vue-apex-charts>

      <!--      <div class="d-flex align-center">-->
      <!--        <h3 class="text-2xl font-weight-semibold me-4">-->
      <!--          45%-->
      <!--        </h3>-->
      <!--        <span>Your sales perfomance in 45% 🤩 better compare to last month</span>-->
      <!--      </div>-->

      <!--      <v-btn-->
      <!--        block-->
      <!--        color="primary"-->
      <!--        class="mt-6"-->
      <!--        outlined-->
      <!--      >-->
      <!--        Details-->
      <!--      </v-btn>-->
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { kFormatter } from '@core/utils/filter'
import { getVuetify } from '@core/utils'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import storeModule from './statisticsStoreModule'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'statistics-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const $vuetify = getVuetify()

    // const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'

    const chartOptions = {
      colors: [
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.primary,
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        offsetX: -15,
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 8,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ['oss', 'B站', '西瓜', '快手', '抖音'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
          formatter: value => `${kFormatter(value, 0)}`,
        },
      },
      stroke: {
        width: [2, 2],
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          right: 0,
        },
      },
    }

    const charts = ref(null)
    const xList = ref([])
    const chartData = ref([
      {
        name: '频道数量',
        data: [],
      },
    ])
    const showPlatform = () => {
      store.dispatch('statistics-list/fetchPlatformLists')
        .then(response => {
          const { data } = response.data
          for (let i = 0; i < data.length; i += 1) {
            chartOptions.xaxis.categories.push(data[i].form)
            xList.value.push(data[i].form)
            chartData.value[0].data.push(data[i].formCount)
          }
          charts.value.updateOptions({
            xaxis: {
              categories: xList.value,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickPlacement: 'on',
              labels: {
                show: true,
                style: {
                  fontSize: '12px',
                },
              },
            },
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
    showPlatform()

    return {
      chartOptions,
      chartData,
      charts,
      showPlatform,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },

  // mounted() {
  //
  //   this.$refs.charts.updateOptions()
  //   console.log(
  //     this.$refs.charts,
  //   )
  // },
}
</script>
