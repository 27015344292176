import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("频道排行")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardSubtitle,{staticClass:"mt-n6 mb-2"},[_c('span',[_vm._v("（近七天）")])]),_c(VCardText,{staticStyle:{"min-height":"334px"}},[_c(VList,{staticClass:"pb-0"},_vm._l((_vm.channelList),function(data,index){return _c(VListItem,{key:data.videoNames,class:("d-flex align-center px-0 " + (index > 0 ? 'mt-4':''))},[_c(VAvatar,{class:("v-avatar-light-bg " + (data.color) + "--text font-weight-medium me-3"),attrs:{"color":data.color,"size":"40"}},[_c('span',{staticClass:"text-base"},[_vm._v(_vm._s(data.videoNames?data.videoNames.slice(0,1):' '))])]),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap"},[_c('div',{staticClass:"me-2"},[_c('div',{staticClass:"font-weight-semibold"},[_c('span',{staticClass:"text--primary text-base me-1"},[_vm._v(_vm._s(_vm._f("fileConversion")(data.videoSize)))])]),_c(VListItemSubtitle,{staticClass:"text-xs"},[_vm._v(" "+_vm._s(data.videoNames)+" ")])],1),_c(VSpacer),_c('div',[_c('h4',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(data.videoCount)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v("视频量")])])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }