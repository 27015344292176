import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("平台")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c('vue-apex-charts',{ref:"charts",attrs:{"options":_vm.chartOptions,"series":_vm.chartData,"height":"318"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }